import BaseBean from "@/utils/BaseBean";

export interface ICxListDataObj {
    utilInst:CxListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams:any
}

export default class CxListUtil extends BaseBean{
    public dataObj:ICxListDataObj

    constructor(proxy:any,dataObj:ICxListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    public getSelectRowId():Array<string>{
        let selectRows = this.dataObj.pageListRef.getTbInst().getSelection();
        if (selectRows.length == 0) {
            this.utils.Tools.info({message: "请选择要操作的行"});
            return[];
        }
        return selectRows.map((item:any)=>{
            return item.F_ID;
        });
    }
    public async batchOperate(options:any):Promise<void> {
        let selectRows = this.getSelectRowId();
        if(selectRows.length==0)return ;
        Object.assign(options,{selectRows:selectRows});
        if('batchDel'==options.type){
            await this.batchDel(options);
        }else{
            this.dataObj.otherParams.dialogVisible=true;
        }
    }
    //批量删除
    public async batchDel(options:any):Promise<void>{
        this.utils.Tools.configBox({
            message:'确定批量删除所选车型?',
            sureFn:async ()=> {
                let res=await this.utils.ToolsProviderApi.batchCx(options);
                if(res.result)this.dataObj.pageListRef.queryHandler();
            }
        });
    }
    //批量转移
    public async batchMoveSure():Promise<void>{
        if(!this.dataObj.otherParams.moveBrandId){
            this.proxy.$message('请选择品牌');
        }else{
            this.dataObj.otherParams.dialogVisible=false;
            let selectRows = this.getSelectRowId();
            let options=Object.assign({type:'batchMove',selectRows:selectRows,moveBrandId:this.dataObj.otherParams.moveBrandId})
            let res=await this.utils.ToolsProviderApi.batchCx(options);
            if(res.result)this.dataObj.pageListRef.queryHandler();
        }
    }
}