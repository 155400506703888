import CxCard from './CxCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import CxListUtil,{ICxListDataObj} from './cxListUtil';
export default defineComponent ({
    name: 'CxList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:ICxListDataObj=reactive<ICxListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                multiple:true,
                queryParam: {},
                modelComp: CxCard,
                modelMethod: utils.ToolsProviderApi.buildUrl('/cx/pageData')
            },
            otherParams:{
                toolBrandData:[],
                moveBrandId:'',//批量转移到哪个品牌
                dialogVisible:false//批量转移弹出框控制字段
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new CxListUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
            dataObj.otherParams.toolBrandData=await utils.ToolsProviderApi.getAllToolBrand({flag:0});
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'brandId'==params.comboId){
                    // return [{value:1,label:'金杯'},{value:2,label:'斯威'},{value:3,label:'新能源'}]
                    return dataObj.otherParams.toolBrandData;
                }
            }
        })

        //批量删除
        const batchDelHandler=()=>{
            dataObj.utilInst.batchOperate({type:'batchDel'});
        }
        //批量转移
        const batchMoveHandler=()=>{
            dataObj.utilInst.batchOperate({type:'batchMove'});
        }
        //批量转移确认事件
        const sureBatchMoveHandler=async ()=>{
            await dataObj.utilInst.batchMoveSure()
        }
        const setDelNoteMsg=async (row:any)=>{
            return "删除车型后，该车型下方关联的车辆手册将被一并删除。";
        }
        return{
            ...toRefs(dataObj),comboSelect,batchDelHandler,batchMoveHandler,sureBatchMoveHandler,setDelNoteMsg
        }
    }
});